import { withDependencies, named } from '@wix/thunderbolt-ioc'
import { PageFeatureConfigSymbol, IPageWillMountHandler, IPropsStore, Props } from '@wix/thunderbolt-symbols'
import { CurrentUrlPageConfig } from './types'
import { name } from './symbols'
import { IUrlHistoryManager, UrlHistoryManagerSymbol } from 'feature-router'

const currentUrlFactory = (
	pageFeatureConfig: CurrentUrlPageConfig,
	propsStore: IPropsStore,
	urlHistoryManager: IUrlHistoryManager
): IPageWillMountHandler => {
	return {
		pageWillMount: async () => {
			const { componentsIds } = pageFeatureConfig
			const currentUrl = urlHistoryManager.getParsedUrl()
			const updatedProps = componentsIds.reduce(
				(updatedObject, compId) => ({
					...updatedObject,
					...{
						[compId]: {
							currentUrl,
						},
					},
				}),
				{}
			)
			propsStore.update(updatedProps)
		},
	}
}

export const CurrentUrl = withDependencies(
	[named(PageFeatureConfigSymbol, name), Props, UrlHistoryManagerSymbol],
	currentUrlFactory
)
